import { useEffect } from 'react';

type TimeoutAction = {
  selector: string;
  className: string;
  delay: number;
};

const useTimeoutActions = (actions: TimeoutAction[]) => {
  useEffect(() => {
    const timeouts: NodeJS.Timeout[] = actions.map(({ selector, className, delay }) =>
      setTimeout(() => {
        const element = document.querySelector(selector);
        element?.classList.add(className);
      }, delay)
    );

    return () => {
      timeouts.forEach(clearTimeout);
    };
  }, [actions]);
};

export default useTimeoutActions;