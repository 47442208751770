import { useLocation } from "react-router-dom";
import "./Header.scss";

const NavLink = ({ path, text }: { path: string, text: string }) => {
    const location = useLocation();
    const currentPath = location.pathname;
    const className = currentPath === path ? "active" : "";

    return (
        <a href={path} className={className}>{text}</a>
    );
};

const Header = () => {
    return (
        <header>
            <a href="/" id="logo" aria-label="home"><i className="bi bi-suit-spade-fill"></i></a>
            <nav id="main-nav">
                <NavLink path="/" text="Home" />
                <NavLink path="/education" text="Education" />
                <NavLink path="/experience" text="Experience" />
                <NavLink path="/projects" text="Projects" />
                <NavLink path="/travel" text="Travel" />
                <NavLink path="/contact" text="Contact" />
            </nav>
        </header>
    );
};

export default Header;