import selfie from './selfie.png';
import useTimeoutActions from 'hooks/useTimeoutActions';
import './Home.scss';

const Home = () => {
    useTimeoutActions([
        { selector: '#hello-world', className: 'hide-caret', delay: 3000 },
        { selector: '#name', className: 'write', delay: 3200 },
        { selector: '#name', className: 'hide-caret', delay: 5000 },
    ]);

    return (
        <div>
            <div id="intro">
                <div className="headings-container">
                    <div className="headings">
                        <span id="hello-world"></span>
                        <h1 id="name"></h1>
                    </div>
                    <div className="subheadings">
                        <h2 className="coffee-filled-label">Software Developer</h2>
                        <h2 className="coffee-filled-label">BSc Computer Science</h2>
                        <h2 className="coffee-filled-label">Toronto, Canada</h2>
                        {/* <a href="#about" className="coffee-button">Learn more<i className="bi bi-arrow-right"></i></a> */}
                    </div>
                </div>
                <div className="img-container">
                    <img src={selfie} alt="Chloe standing with her arms crossed" />
                </div>
            </div>
            <div id="about">
                <h2>About Me</h2>
            </div>
        </div>
    );
};

export default Home;