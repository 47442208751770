import React from 'react';
import Header from './components/header/Header';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Experience from '@pages/experience/Experience';
import Projects from '@pages/projects/Projects';
import Travel from '@pages/travel/Travel';
import Contact from '@pages/contact/Contact';
import Home from '@pages/home/Home';
import Education from '@pages/education/Education';
import "bootstrap-icons/font/bootstrap-icons.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/education" element={<Education />} />
              <Route path="/experience" element={<Experience />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/travel" element={<Travel />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
